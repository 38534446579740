import {library} from '@fortawesome/fontawesome-svg-core';
import {
    faCaretUp,
    faCog,
    faImage,
    faPlus,
    faSpinner,
    faTrashAlt,
    faTimes,
} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import Vue from 'vue';

library.add(
    faCaretUp,
    faCog,
    faPlus,
    faImage,
    faSpinner,
    faTrashAlt,
    faTimes,
);

Vue.component('FontAwesomeIcon', FontAwesomeIcon);
