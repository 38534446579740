import utils, {axiosWithAuth} from '@imt/vue-toolbox/src/utils';
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export const axios = axiosWithAuth();

export const actions = {
    addNotification({commit, state}, {notification, paging}) {
        commit('ADD_NOTIFICATION', { notification, paging });
        commit('SET_STATE_VALUE', { field: 'totalNotificationCount', value: state.totalNotificationCount + 1 });
    },
    async fetchNotifications({commit}, {page = 1, pageSize = 25}) {
        const response = await axios.get(`${process.env.VUE_APP_ADMIN_URL_USERS}/api/v1/notifications/?page=${page}&page[size]=${pageSize}`);

        commit('SET_STATE_VALUE', { field: 'notifications', value: utils.dataFormatter.deserialize(response.data) });
        commit('SET_STATE_VALUE', { field: 'totalNotificationCount', value: response.data.meta.pagination.count });

        return response.data;
    },
};

export const mutations = {
    ADD_NOTIFICATION(state, {notification, paging}) {
        if (!state.notifications.find(n => n.id === notification.id)) {
            let newNotifications = state.notifications;

            if (newNotifications.length >= paging.pageSize) {
                newNotifications.pop();
            }

            state.notifications = [
                notification,
                ...newNotifications,
            ];
        }
    },
    SET_STATE_VALUE(state, data) {
        state[data.field] = data.value;
    },
    UPDATE_NOTIFICATION(state, notification) {
        state.notifications = state.notifications.map(n => {
            if (n.id === notification.id) {
                return notification;
            }

            return n;
        });
    },
};

export const state = () => {
    return {
        totalNotificationCount: 0,
        adminKey: 'notificationCenter',
        notifications: [],
    };
};

export default {
    actions,
    mutations,
    namespaced: true,
    state: state(),
};
