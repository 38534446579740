<template>
    <div>
        <div
            v-if="permissionsLoaded && hasAccess"
            id="app"
            class="container-fluid"
        >
            <div class="row flex-nowrap">
                <div class="col-auto px-0">
                    <Menu :default-menu="null">
                        <template #section-one-heading="{collapsed}">
                            <MenuHeading
                                title="IMT Platform"
                                icon="cube"
                                :collapsed="collapsed"
                            />
                        </template>

                        <template #section-one-items="{collapsed}">
                            <MenuItem
                                v-for="item in menuItems.filter(i => i.section === 1)"
                                :key="item.title"
                                :collapsed="collapsed"
                                :href="item.href"
                                :icon="item.icon"
                                :target="item.target"
                                :to="item.to"
                                :title="item.title"
                            />
                        </template>

                        <template #section-two-heading="{collapsed}">
                            <MenuHeading
                                title="User"
                                icon="user"
                                :collapsed="collapsed"
                            />
                        </template>

                        <template #section-two-items="{collapsed}">
                            <MenuItem
                                v-for="item in menuItems.filter(i => i.section === 2)"
                                :key="item.title"
                                :collapsed="collapsed"
                                :href="item.href"
                                :icon="item.icon"
                                :target="item.target"
                                :to="item.to"
                                :title="item.title"
                            />
                        </template>
                    </Menu>
                </div>

                <div class="col px-0 d-flex flex-column">
                    <div class="container-fluid">
                        <InAppAlerts data-cy="inAppAlerts" />
                    </div>

                    <Header
                        :title="pageTitle"
                    />

                    <div
                        class="container-fluid"
                    >
                        <RouterView
                            :mode="mode"
                            @toggle-mode="toggleMode($event)"
                        />
                    </div>

                    <div class="footer d-flex flex-row m-0 mt-auto align-items-end">
                        <div class="col-12 col-lg-4">
                            <a
                                class="btn btn-link p-0"
                                href="https://ticket.imtins.com/status/endpoints"
                                target="_blank"
                            >
                                Platform Status
                            </a>
                        </div>

                        <div class="col-6 col-md text-center text-size-xs text-muted text-size-tiny copyright">
                            <span class="d-none d-md-inline-block">&copy;{{ copyrightText }}</span>
                        </div>

                        <div class="col-6 col-md-4 d-flex text-right">
                            <button
                                ref="backToTop"
                                v-scroll-to="'#app'"
                                class="btn btn-link btn-back-to-top p-0 m-0 ml-auto text-size-xs"
                            >
                                <FontAwesomeIcon
                                    icon="caret-up"
                                    class="mr-1"
                                />
                                Back to top
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <Forbidden v-else-if="permissionsLoaded && !hasAccess" />
    </div>
</template>

<script>
    import {library} from '@fortawesome/fontawesome-svg-core';
    import {faCaretUp} from '@fortawesome/pro-solid-svg-icons';
    import Header from '@imt/vue-admin-menus/src/components/Header.vue';
    import InAppAlerts from '@imt/vue-admin-menus/src/components/InAppAlerts.vue';
    import Menu from '@imt/vue-admin-menus/src/components/Menu.vue';
    import MenuHeading from '@imt/vue-admin-menus/src/components/MenuHeading.vue';
    import MenuItem from '@imt/vue-admin-menus/src/components/MenuItem.vue';
    import {platformAdminMenuItems} from '@imt/vue-admin-menus/src/menus';
    import Forbidden from '@imt/vue-kit-car/src/components/errors/Forbidden.vue';
    import modeMixin from '@imt/vue-kit-car/src/mixins/modes';
    import dayjs from 'dayjs';
    import {mapState} from 'vuex';

    import authMixin from '@/mixins/auth';

    library.add(faCaretUp);

    export default {
        name: 'App',
        components: {
            Header,
            Menu,
            InAppAlerts,
            MenuHeading,
            MenuItem,
            Forbidden,
        },
        mixins: [
            authMixin,
            modeMixin,
        ],
        computed: {
            copyrightText: () => `Copyright ${dayjs().year()} IMT Insurance. All rights reserved.`,
            pageTitle() {
                return this.$route.meta.title || '';
            },
            menuItems() {
                return platformAdminMenuItems(this.adminUrls).map(item => {
                    return ['dashboard', 'companies', 'notification center'].includes(item.title.toLowerCase()) ? {
                        ...item,
                        to: this.mapNavItemRoute(item.title),
                    } : item;
                });
            },
            userHasPermission() {
                return this.permissions.platform_admin_access || process.env.VUE_APP_ADMIN_URL_USERS.indexOf('localhost') !== -1;
            },
            ...mapState('toolbox', [
                'permissions',
                'user',
            ]),
        },
        methods: {
            mapNavItemRoute(title) {
                return {
                    companies: {name: 'admin.companies'},
                    dashboard: {name: 'admin.dashboard'},
                    'notification center': {name: 'notification-center'},
                }[title.toLowerCase()];
            },
        },
    };
</script>

<style lang="sass">
    @import "~@imt/vue-kit-car/src/sass/kit-car"
</style>
