import BootstrapVue from 'bootstrap-vue';
import Vue from 'vue';
import VueScrollTo from 'vue-scrollto';

import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import '@/plugins/analytics';
import '@/plugins/font-awesome';
import '@/plugins/sentry';
import '@/plugins/vee-validate';

Vue.use(BootstrapVue);
Vue.use(VueScrollTo, {
    duration: 300,
    easing: 'ease',
    offset: 0,
    force: true,
    cancelable: true,
    onStart: false,
    onDone: false,
    onCancel: false,
    x: false,
    y: true,
});

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app');
