import {ValidationObserver, ValidationProvider} from 'vee-validate';
import {extend} from 'vee-validate';
import {required, max} from 'vee-validate/dist/rules';
import Vue from 'vue';

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

extend('required', {
    ...required,
    message: '{_field_} is required',
});

extend('max', {
    ...max,
    message: '{_field_} cannot be longer than {length} characters',
});
