import auth from '@imt/vue-toolbox/src/mixins/auth';
import {mapState} from 'vuex';

export default {
    computed: {
        canManage() {
            return this.hasAccess && !!this.permissions.company_manage;
        },
        hasAccess() {
            return !!this.permissions.platform_admin_access;
        },
        permissionsLoaded() {
            return !!this.user.id;
        },
        ...mapState('toolbox', [
            'permissions',
            'user',
        ]),
    },
    mixins: [auth]
};
