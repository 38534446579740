import {UUID_REGEX} from '@imt/vue-toolbox/src/utils/index';
import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            redirect: {
                name: 'admin',
            },
        },
        {
            path: '/admin',
            component: () => import(/* webpackChunkName: "base" */ '@/views/PABase.vue'),
            children: [
                {
                    path: '',
                    name: 'admin',
                    redirect: {
                        name: 'admin.dashboard',
                    },
                },
                {
                    path: 'dashboard',
                    name: 'admin.dashboard',
                    meta: {
                        title: 'Dashboard',
                    },
                    component: () => import(/* webpackChunkName: "dashboard" */ '@/views/PADashboard.vue'),
                },
                {
                    path: 'companies',
                    name: 'admin.companies',
                    component: () => import(/* webpackChunkName: "companies" */ '@/views/PACompanies.vue'),
                    meta: {
                        title: 'Companies',
                    },
                    children: [
                        {
                            path: 'new',
                            name: 'admin.companies.add',
                            component: () => import(/* webpackChunkName: "company-modal" */ '@/views/PACompanyModal.vue'),
                            meta: {
                                title: 'Companies',
                            }
                        },
                        {
                            path: `:companyId(${UUID_REGEX})`,
                            name: 'admin.companies.options',
                            component: () => import(/* webpackChunkName: "company-modal" */ '@/views/PACompanyModal.vue'),
                            meta: {
                                title: 'Companies',
                            }
                        }
                    ]
                },
                {
                    path: 'notification-center',
                    name: 'notification-center',
                    component: () => import(/* webpackChunkName: "notification-center" */ '@/views/PANotificationCenter.vue'),
                    meta: {
                        title: 'Notification Center',
                    }
                },
            ]
        },
        {
            path: '*',
            name: 'not-found',
            component: () => import(/* webpackChunkName: "not-found" */ '@imt/vue-kit-car/src/components/errors/NotFound.vue'),
            meta: {
                title: 'Not Found',
            }
        },
    ]
});

router.beforeEach((to, from, next) => {
    document.title = `${to.meta.title} | IMT Platform`;

    next();
});

export default router;
